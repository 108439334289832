.fixed-header{
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    position: fixed;        
    background: var(--grey-grey-md, #F8F8F8);
    color: #fff;
    border-bottom: 1px solid var(--grey-light-grey, #DDD);
    z-index: 100;
  
  }
  nav {
    width:100%;
    padding: 7px 25px;  
    display: flex;
    justify-content: center;
    .storeName{
      color: var(--pure-pure-black, #000);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: "Inter";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 140% */
      letter-spacing: 0.75px;
    }
  
  }