@use "../../theme/colors.scss";

.ExButton {
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  vertical-align: middle;
  cursor: pointer;
  gap: 16px;
  padding: 24px 32px;
  color: white;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  width: 100%;
}

.disabledPrimary {
  @extend .ExButton;
  background-color: var(--primaryColorDisabled);
}
.disabledSecondary {
  @extend .ExButton;
  background-color: var(--primaryBlackColorDisabled);
}

.enabledPrimary {
  @extend .ExButton;
  background-color: var(--primaryColor);
  transition: 0.3s;
  &:hover {
    background-color: var(--primaryColorHover);
    transition: 0.3s;
  }
}

.enabledSecondary {
  @extend .ExButton;
  background-color: var(--primaryBlackColor);
  transition: 0.3s;
  &:hover {
    background-color: var(--primaryBlackColorHover);
    transition: 0.3s;
  }
}

.iconOnlyXl {
  @extend .ExButton;
  width: 70px !important;
  padding: 20px 0px !important;
}

.iconOnlyLg {
  @extend .ExButton;
  width: 70px !important;
  padding: 20px 0px !important;
}

.iconOnlyMd {
  @extend .ExButton;
  width: 60px !important;
  padding: 20px 0px !important;
}

.iconOnlySm {
  @extend .ExButton;
  width: 43px !important;

  padding: 20px 0px !important;
}

.buttonLabel {
  font-family: "Inter" !important;
  text-align: center;
  font-weight: 500;
  line-height: 1;
}
.labelxl {
  @extend .buttonLabel;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1.5px;
  line-height: 1;
}

.labellg {
  @extend .buttonLabel;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.75px;
  line-height: 1;
}
.labelmd {
  @extend .buttonLabel;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.75px;
  line-height: 1;
}

.labelsm {
  @extend .buttonLabel;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 1;
}

.buttonxl {
  padding: 20px 32px;
  width: 106px;
}

.buttonlg {
  padding: 20px 32px;
  width: 96px;
}

.buttonmd {
  padding: 16px 24px;
  width: 100%;
}

.buttonsm {
  padding: 9px 16px;
  width: 100%;
}
