@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

.maxLengthLabel {
  font-family: "Inter" !important;
  align-self: end !important;
}

.label {
  font-family: "Inter" !important;
  font-weight: 600;
}

.textFieldWrapper {
  background-color: #2645e60d;
  border: 2px solid transparent;
  border-radius: 8px;
  width: 100%;
  font-family: "Inter";
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;

  &:hover {
    background-color: #fff;
    border: 2px solid #001d3d;
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;
  }
}
.focusedWrapper {
  background-color: #fff;
  border: 2px solid #001d3d;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  transition: 0.4s;
  justify-content: center;
  box-shadow: 0px 4px 8px #00000040;
}

.textfieldWrapperMd {
  height: 52px;
  padding: 3px 10px 5px 10px;
}
.textfieldWrapperSm {
  height: 54px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE */
}

.textAreaWrapper {
  padding: 5px 5px 10px 5px;
}

.iconErrorStyle {
  color: #ed2e7e !important;
}
.iconCorrectStyle {
  color: #00ba88 !important;
}

.errorStyle {
  border: 2px solid #ed2e7e;
  background-color: #fff3f8;
  color: #00ba88;
}
.correctStyle {
  border: 2px solid #00ba88;
  background-color: #f2fffb;
  color: #00ba88;
}

.correctTextArea {
  border: 2px solid #27ae60;
  background-color: #eaf9de;
  color: #27ae60;
}

.subLabel {
  color: #6e7191;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.25px;
}
.errorSubLabel {
  color: #ed2e7e;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter";
}
.correctSubLabel {
  color: #00966d;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter";
}

.leftAlign {
  float: left;
  font-family: "Inter";
  margin-top: 8px;
}
.rightAlign {
  float: right;
  font-family: "Inter";
  margin-top: 8px;
}

.disabledTextFieldWrapper {
  background-color: #2645e60d;
  border: 2px solid transparent;
  opacity: 0.7;
  border-radius: 8px;
  width: 100%;
  font-family: "Inter";
  display: flex;
  align-items: center;
  justify-content: center;
}
