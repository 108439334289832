@use "../../theme/colors.scss";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.ExFormLabel {
}

.formControlLabel {
  font-family: "Inter" !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 28px !important;
  letter-spacing: 0.75px !important;
}

.labelPlacementRight {
  @extend .formControlLabel;
  margin-right: var(--form-label-margin) !important;
}

.labelPlacementLeft {
  @extend .formControlLabel;
  margin-left: var(--form-label-margin) !important;
}
