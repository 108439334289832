@import url("https://fonts.googleapis.com/css?family=Inter");

.App {
  text-align: center;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-self: center;
  width: 100%;
}

body{
  padding-top: 60px;
  overflow: auto;
  padding-bottom: 40px;
}
.fixed-header, .fixed-footer{
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  position: fixed;        
  background: var(--grey-grey-md, #F8F8F8);
  color: #fff;
  border-bottom: 1px solid var(--grey-light-grey, #DDD);
  z-index: 100;

}
.fixed-header{
  top: 0;
}
.fixed-footer{
  bottom: 0;
  background: var(--pure-pure-withe, #FFF);
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.10);
  padding: 40px 20px;
  display: flex;
  width: 100%;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}
.container{
  display: flex;
  align-items: center;
  justify-content: center;
width: 100%;
  .col-4{
    flex:1;
  }
  .centerColumn{
    flex:1;
    max-width: 378px;
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 30px 20px;

    .sectionTitle{
      color: #000;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      text-align: start;
      font-weight: 700;
      line-height: 18px;
      margin-bottom: 10px;
    }
    .form{
      display:flex;
      flex-direction: column;
    justify-content: center;
   
    }
    .poweredBy{
      color: var(--Black-2, #222);
      font-family: "Poppins";
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 280% */
    }
    .logoWrapper{
      width: 100%;
      margin-top: 0px;
      >svg{
        max-width: 160px!important;
        height: 59px;
      }
    }

  }
}
nav {
  width:100%;
  padding: 7px 25px;  
  display: flex;
  justify-content: center;
  .storeName{
    color: var(--pure-pure-black, #000);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
    letter-spacing: 0.75px;
  }

}

@media (max-width: 900px) {
  .col-4 {
    display: none !important;
  }
}

@media (max-height: 1057px) {
  .centerColumn{
    
    padding-bottom: 100px!important;
  }
}