@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

.label {
  font-family: "Inter" !important;
  font-weight: 600;
}

.textFieldWrapper {
  background-color: #2645e60d;
  border: 2px solid transparent;
  border-radius: 8px;
  width: 100%!important;
  font-family: "Inter";
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  font-family: "Inter";

  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE */
  height: 49px;
  margin-bottom: 12px;
  overflow: hidden;

  &:hover {
    background-color: #fff;
    border: 2px solid #001d3d;
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.4s;
  }
}

.focusedWrapper {
  background-color: #fff;
  border: 2px solid #001d3d;
  border-radius: 8px;
  width: 100%;
  box-shadow: 0px 4px 8px #00000040;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  font-family: "Inter";
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE */
  margin-bottom: 12px;
}

.disabledTextFieldWrapper {
  background-color: #2645e60d;
  border: 2px solid transparent;
  opacity: 0.7;
  border-radius: 8px;
  width: 100%;
  font-family: "Inter";
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE */
}
